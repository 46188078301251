<template>
  <Dialog v-model:visible="isShow" :header="title" @hide="closeDialog">
    <div v-if="display" id="video-container" style="width:758px;height:430px"></div>
  </Dialog>
</template>

<script>
import EZUIKit from "ezuikit-js";

let video = null;
export default {
  data() {
    return {
      isShow: false,
      title: null,
      display: false,
    };
  },
  methods: {
    show(accessToken, serialNumber, title) {

      this.display = true;
      video = null;
      this.isShow = true;
      this.title = title;
      this.$nextTick(() => {
        if (serialNumber.value) {
          video = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: "video-container",
            accessToken: accessToken,
            url: "ezopen://open.ys7.com/" + serialNumber.value + "/1.hd.live",
            template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            //视频上方头部控件
            //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
            // plugin: ['talk'],                       // 加载插件，talk-对讲
            //视频下方底部控件
            // footer: ["talk", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
            audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
            // openSoundCallBack: data => console.log("开启声音回调", data),
            // closeSoundCallBack: data => console.log("关闭声音回调", data),
            // startSaveCallBack: data => console.log("开始录像回调", data),
            // stopSaveCallBack: data => console.log("录像回调", data),
            // capturePictureCallBack: data => console.log("截图成功回调", data),
            // fullScreenCallBack: data => console.log("全屏回调", data),
            // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
            width: 758,
            height: 426,
          });
        } else {
          this.$toast.add({ severity: 'warn', summary: '错误', detail: '视频信息获取有误！', life: 3000 });
        }
      });
    },
    closeDialog() {
      this.display = false;
      video.stop();
      video = null;
      this.$emit("close");
    },
  },
  mounted: () => {
  },
};
</script>
